<template>
  <div class="header">
    <div style="margin-left: 50px;">
      <el-menu mode="horizontal"
               router
               background-color="rgb(60,60,60)"
               text-color="#fff"
               active-text-color="rgb(80,155,90)"
               :default-active="activeIndex2"
               style="border-bottom:none;height: 60px">
        <el-menu-item index="/about">公司简介</el-menu-item>
        <el-menu-item index="/business">双动业务</el-menu-item>
        <el-menu-item index="/history">发展历程</el-menu-item>
        <el-menu-item index="/partner">合作伙伴</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutHeader2",
  props: {
    activeIndex: {
      type: String
    },
    activeIndex2: {
      type: String
    }
  }
}
</script>

<style scoped>
.header {
  background: rgb(60, 60, 60);
  text-align: center;
  /*line-height: 60px;*/
  height: 60px;
  width: 100%;
  position: sticky;
  z-index: 6;
  top: 0;
  min-width: 960px;
  box-shadow: 0 0 3px #404040;
  /*padding-right: 50px;*/
  /*padding-left: 50px;*/
}

.router-link-active {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid rgb(80, 155, 90);
  color: #303133;
}

</style>