<template>
    <div class="all">
        <Home2Header active-index="/about"></Home2Header>
        <AboutHeader2 active-index2="/partner"></AboutHeader2>
        <div class="main">
            <div :style="background" class="bg">
                <div class="text">
                    <p style="font-size: 28px">
                        开放、合作、共赢
                    </p>
                    <p>让我们一起实现客户价值</p>
                </div>
            </div>

            <div class="pic">
              <el-row :gutter="30" style="margin-top: 20px;margin-bottom: 20px">
                <el-col :span="6">
                  <el-card shadow="never"
                           style="">
                    <el-image :src="require('../../assets/About/2.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never"
                           style="">
                    <el-image :src="require('../../assets/About/3.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never"
                           style="">
                    <el-image :src="require('../../assets/About/4.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never"
                           style="margin: 0;padding: 0;">
                    <el-image :src="require('../../assets/About/5.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
              </el-row>

              <el-row :gutter="30" style="margin-top: 20px;margin-bottom: 20px">
                <el-col :span="6">
                  <el-card shadow="never"
                           style="">
                    <el-image :src="require('../../assets/About/6.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never"
                           style="">
                    <el-image :src="require('../../assets/About/7.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never"
                           style="">
                    <el-image :src="require('../../assets/About/8.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
                <el-col :span="6">
                  <el-card shadow="never"
                           style="margin: 0;padding: 0;">
                    <el-image :src="require('../../assets/About/9.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
              </el-row>

              <el-row :gutter="30" style="margin-top: 20px;margin-bottom: 20px">
                <el-col :span="6">
                  <el-card shadow="never"
                           style="">
                    <el-image :src="require('../../assets/About/10.jpg')" lazy style="height: 100%;width: 100%"/>
                  </el-card>
                </el-col>
              </el-row>

            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Home2Header from "../../components/Home2Header";
    import AboutHeader2 from "../../components/AboutHeader2";
    import Footer from "../../components/Footer";

    export default {
        name: "Partner",
        data() {
            return {
                background: {
                    background: 'url(' + require('../../assets/About/1.jpg') + ')',
                    backgroundSize: '100% 100%',
                },
            }
        },
        methods: {},
        components: {
            Home2Header, AboutHeader2, Footer
        },
        props: {
            activeIndex: {
                type: String
            },
            activeIndex2: {
                type: String
            }
        },
    }
</script>

<style scoped>
    .all{
        min-width:960px;
    }
    .main {
        margin: 0 0 0 0;
    }

    .bg {
        height: 28.7vw;
        width: 100%;
        min-width: 960px;
        min-height: 275px;
        position: relative;
    }

    .text {
        position: absolute;
        z-index: 2;
        color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .pic {
        margin: 0 10%;
    }

    >>>.el-card__body{
      padding: 0;
    }

    /*.pic img {*/
    /*    border: 1px solid #e8e8e8;*/
    /*    height: 8vw;*/
    /*    width: 17vw;*/
    /*    margin: 10px;*/
    /*  min-height: 77px;*/
    /*  min-width: 163px;*/
    /*}*/
</style>